<template>
  <div class="settings-clinical-team">
    <vue-good-table
      :ref="tableReference"
      :is-loading="isLoading"
      :total-rows="totalRecords"
      :columns="columns"
      :rows="clinicianTeams"
      style-class="vgt-table"
      mode="remote"
      :pagination-options="{
        enabled: true
      }"
      :search-options="{
        enabled: true,
        externalQuery: settingsSearch
      }"
      :max-height="maxHeight"
      :fixed-header="true"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearchTermChange"
      @on-row-click="openEditTeam"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.label == $t('clinicianTeamListTable.team')">
          <span class="team-name">{{ props.row.name }}</span>
        </span>
      </template>
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ $t('noResultsFound') }}
      </div>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
    <overlay
      v-if="routeMatch"
      @close="closeOverlay"
    >
      <template slot="content">
        <router-view />
      </template>
    </overlay>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { CliniciansTeamList } from '@/mixins/TableColumnsHelper'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import { SettingsCrud } from '@/mixins/SettingsCrud'
import PaginationFooter from '@/components/common/PaginationFooter'
import { GET_ALL_CLINICIANS_TEAMS } from '@/store/modules/clinicians/constants.js'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import Overlay from '@/components/clinician/Overlay'

export default {
  name: 'SettingsClinicalTeam',
  components: {
    Overlay,
    VueGoodTable,
    LoadingSpinner,
    PaginationFooter
  },
  mixins: [Helpers, CliniciansTeamList, RemoteTable, SettingsCrud],
  data () {
    return {
      tableMaxHeight: 154,
      tableReference: 'SettingsClinicalTeam',
      action: GET_ALL_CLINICIANS_TEAMS,
      defaultSortOrder: 'asc',
      defaultSortField: 'name'
    }
  },
  computed: {
    ...mapGetters({
      clinicianTeams: 'getClinicianTeams'
    }),
    routeMatch () {
      const routeNameAllowed = [
        'ClinicianAddTeam',
        'ClinicianEditTeam'
      ]
      return routeNameAllowed.indexOf(this.$route.name) !== -1
    }
  },
  created () {
    this.loadItems()
  },
  methods: {
    openAdd () {
      this.$router.push({ name: 'ClinicianAddTeam' })
    },
    openEditTeam (params) {
      this.$router.push({ name: 'ClinicianEditTeam', params: { teamId: params.row.id } })
    },
    closeOverlay () {
      this.$router.push({ name: 'SettingsClinicalTeam' })
    }
  }
}
</script>
